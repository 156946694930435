<template>
  <v-container>
    <v-card>
      <v-row>
        <v-col cols="12">
          <v-toolbar
            dark
            color="secondary"
          >
            <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
            <v-toolbar-title>Registrar una tarea o una orden... NO USAR. SON ORDENES.</v-toolbar-title>
            <v-spacer />
            <v-btn
              color="red"
              @click="validaInfo"
            >
              Agregar
            </v-btn>
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="numcont"
            prepend-icon="account-circle"
            class="ma-3"
            label="Contacto"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="empresa"
            class="ma-3"
            label="Empresa"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="lugar"
            class="ma-3"
            label="Lugar"
          />
        </v-col>
      </v-row>

      <v-divider />

      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="lugar"
            class="ma-3"
            label="Asunto"
          />
          <v-textarea
            v-model="temas"
            height="70"
            class="ma-3"
            outlined
            name="input-7-4"
            label="Temas a tratar"
          />
        </v-col>

        <v-col cols="3">
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            safely
            transition="scale-transition"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="fecha"
                class="ma-3"
                label="Fecha"
                persistent-hint
                prepend-icon="event"
                v-on="on"
              />

              <v-text-field
                v-model="duracion"
                class="ma-3"
                prepend-icon="timer"
                label="Duración (minutos)"
                type="number"
              />
            </template>
            <v-date-picker
              v-model="fecha"
              no-title
              locale="es"
              @input="menu1 = false"
            />
          </v-menu>
        </v-col>

        <v-col cols="3">
          <v-menu
            ref="menu"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="hora"

            max-width="290px"
            min-width="290px"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="hora"
                class="ma-3"
                label="Hora de la cita"
                prepend-icon="access_time"
                readonly
                v-on="on"
              />
              <v-text-field
                v-model="numproy"
                prepend-icon="notes"
                class="ma-3"
                label="Proyecto"
              />
            </template>
            <v-time-picker
              v-if="menu2"
              v-model="time"
              full-width
              @click:minute="$refs.menu.save(time)"
            />
          </v-menu>
        </v-col>
      </v-row>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      top
      color="info"
    >
      {{ msg }}
      <v-btn
        color="error"
        text
        @click="snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
  </v-container>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'

export default{
  data(){
    return{
      msg:'',
      snackbar: false,
      // minutos:'',
      time: '10:30',
      time2:null,
      detalle:'',
      titulo:'',
      menu2: false,
      menu3: false,

      //    inicio: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      // meta: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    
      date: new Date().toISOString(),
      menu1: false,
      minutos:30,
    }
  },
  created(){
    console.log('fecha',this.date)
  },
  methods:{
    ...mapActions('citas',['addCita']),

    validaInfo(){
      if(this.contacto == '' || this.empresa == '' || this.lugar == '' || this.mensaje == '' || fecha == ''
					 || this.fecha == '' || this.duracion == ''|| this.empresa == ''|| this.hora == ''|| this.proyecto == ''
					 || this.empresa == ''){
        this.msg  = 'Por favor no dejes campos vacios'
        this.snackbar = true
      }else{
        this.agregar()
      }
    },

    agregar(){
      var payload = {
        contacto:   this.numcont,
        empresa:  this.empresa,
        lugar:  this.lugar,

        asunto: this.asunto,
        mensaje:  this.mensaje,

        fecha:    this.fecha,
        duracion: parseInt(this.duracion),

        hora:  this.hora,
        proyecto: this.numproy,
        open:     '0'
      }
      this.addCita(payload)
    }
  }
}
</script>